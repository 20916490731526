import "../styles/globals.css";
import React, { useEffect } from "react";
// import { ConfigProvider } from 'antd';
// import viVN from 'antd/es/locale/vi_VN';
import { wrapper } from "../stores";
import TopProgressBar from "components/TopProgressBar";
import { useRouter } from "next/router";

import "../styles/app.scss";
import "../styles/style.scss";

import * as ga from "services/ga";

export function reportWebVitals({ id, name, label, value }: any) {
  // console.log({ id, name, label, value });
  // initGA();
  // logEvent({
  //   category: label === 'web-vital' ? 'Web Vitals' : 'Custom metrics',
  //   action: name,
  //   label,
  //   value: Math.round(name === 'CLS' ? value * 1000 : value),
  // });

  ga.sendGaEvent({
    action: name,
    params: {
      event_category:
        label === "web-vital" ? "Web Vitals" : "Next.js custom metric",
      value: Math.round(name === "CLS" ? value * 1000 : value), // values must be integers
      event_label: id, // id unique to current page load
      non_interaction: true, // avoids affecting bounce rate.
    },
  });
}

const MyApp = (props: any) => {
  const router = useRouter();

  const handleRouteChange = (url: string) => {
    ga.pageview(url);
  };

  const { Component, pageProps } = props as any;

  useEffect(() => {
    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      <TopProgressBar />
      <Component {...pageProps} />
    </>
  );
};

export default wrapper.withRedux(MyApp);
