interface Node {
  type: "video" | "question";
  position: "pre" | "post";
  content: any;
  session: any;
  next?: Node;
}

export class TopicChain {
  chain: Node | null = null;
  head: Node | null = null;
  topic: any = {};
  currentChain: Node | null = null;

  constructor(topic: any) {
    this.topic = topic;
    this.buildChain();
  }

  addNode(node: Node) {
    if (!this.chain) {
      this.chain = node;
      this.head = this.chain;
    } else {
      this.chain.next = node;
      this.chain = this.chain.next;
    }
  }

  buildChain() {
    if (this.topic) {
      const { sessions } = this.topic ?? {};

      //   console.log("Build chain =", {
      //     us: this.userLearning,
      //   });

      // build progress dict
      //   const obj: any = {};

      //   (this.userLearning ?? []).forEach((l: any) => {
      //     if (l.question) {
      //       const k = `q_${l.question.id}`;
      //       obj[k] = true;
      //     }
      //     if (l.video) {
      //       const k = `v_${l.video.id}`;
      //       obj[k] = true;
      //     }
      //   });

      //   console.log("learning obj", { obj });

      sessions.forEach((session: any) => {
        const { pre_questions, pre_video = [], post_questions } = session;

        if (pre_questions.length > 0) {
          //   const questions = [...pre_questions].map((q) => {
          //     const { id } = q;
          //     if (obj[`q_${id}`]) {
          //       q = { ...q };
          //       q.done = true;
          //     }
          //     return q;
          //   });

          const newNode: Node = {
            type: "question",
            content: pre_questions,
            session,
            position: "pre",
          };
          this.addNode(newNode);
        }

        if (pre_video.length > 0) {
          pre_video.forEach((video: any) => {
            // video = { ...video };
            // if (obj[`v_${video.id}`]) {
            //   video.done = true;
            // }
            const newNode: Node = {
              type: "video",
              content: video,
              session,
              position: "pre",
            };

            this.addNode(newNode);
          });
        }

        if (post_questions.length > 0) {
          //   const questions = [...pre_questions].map((q) => {
          //     const { id } = q;
          //     if (obj[`q_${id}`]) {
          //       q = { ...q };
          //       q.done = true;
          //     }
          //     return q;
          //   });

          const newNode: Node = {
            type: "question",
            content: post_questions,
            session,
            position: "post",
          };
          this.addNode(newNode);
        }
      });

      this.currentChain = this.head;
    }
  }

  next() {
    console.log("Go next");
    if (this.currentChain && this.currentChain.next) {
      console.log("Set current chain");
      this.currentChain = this.currentChain.next;
      return this.currentChain;
    }

    return null;
  }
}
