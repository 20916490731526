import { createSlice } from "@reduxjs/toolkit";
import cloneDeep from "lodash.clonedeep";
import { HYDRATE } from "next-redux-wrapper";
import { TopicChain } from "utils/topicChain";
import { TopicStore } from "./types";

const initialState: TopicStore = {
  loading: false,
  topicPopupOpen: false,
  userLearning: [],
  userProgessKey: {},
  allDone: false,
};

export const TopicSlice = createSlice({
  name: "topic-store",
  initialState,
  reducers: {
    setTopic: (state, action) => {
      state.topic = action.payload;
      state.topicChain = new TopicChain(action.payload);
      state.currentView = state.topicChain.currentChain?.type;
      state.viewData = state.topicChain.currentChain?.content;
    },
    setCurrentTopic: (state, action) => {
      state.currentTopic = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setTopicPopupOpen: (state, action) => {
      state.topicPopupOpen = action.payload;
    },

    setCurrentView: (state, action) => {
      state.currentView = action.payload;
    },
    setCurrentViewData: (state, action) => {
      state.viewData = action.payload;
    },

    setSection: (state, action) => {
      state.section = action.payload;
    },

    startLearning: (state) => {
      state.currentView = state.topicChain?.currentChain?.type;
      state.viewData = state.topicChain?.currentChain?.content;
    },

    setNextItem: (state) => {
      try {
        if (
          state.topicChain?.currentChain &&
          state.topicChain.currentChain.next
        ) {
          state.topicChain.currentChain = state.topicChain.currentChain.next;
          state.currentView = state.topicChain?.currentChain.type;
          state.viewData = state.topicChain?.currentChain.content;
          state.position = state.topicChain.currentChain.position;
        }
      } catch (error) {
        console.log("Next error", error);
      }
    },

    changeSection: (state, { payload }) => {
      const { type, content, session } = payload;
      const topicChain = cloneDeep(state.topicChain);

      state.section = session;

      if (topicChain) {
        topicChain.currentChain = topicChain.head;
        while (true) {
          if (type === "question") {
            if (
              topicChain.currentChain?.type === type &&
              topicChain.currentChain?.session.id === session.id
            ) {
              state.currentView = type;
              state.viewData = content;
              state.topicChain = topicChain;
              state.position = topicChain.currentChain?.position;
              break;
            }
          }

          if (type === "video") {
            if (
              topicChain.currentChain?.type === "video" &&
              topicChain.currentChain.content.id == content.id
            ) {
              state.currentView = type;
              state.viewData = content;
              state.topicChain = topicChain;
              state.position = topicChain.currentChain?.position;
              break;
            }
          }
          if (topicChain.currentChain?.next) {
            topicChain.currentChain = topicChain.currentChain.next;
            continue;
          }

          break;
        }
      }
    },

    setUserLearning: (state, { payload }) => {
      state.userLearning = payload;
      try {
        const obj: any = {};

        (payload ?? []).forEach((l: any) => {
          if (l.question) {
            const k = `q_${l.question.id}`;
            obj[k] = true;
          }
          if (l.video) {
            const k = `v_${l.video.id}`;
            obj[k] = true;
          }
        });
        state.userProgessKey = obj;
        state.userLearning = payload;

        const topic = cloneDeep(state.topic);

        let isNotSeen = true;

        console.log({
          obj,
        });

        const currentAllDone = topic?.sessions.every((session) => {
          const { pre_questions, post_questions, pre_video } = session;

          if (pre_questions.length > 0) {
            const cureQ = pre_questions.every((q) => {
              const isHave = !!obj[`q_${q.id}`];
              if (isHave) {
                isNotSeen = false;
              }
              return isHave;
            });
            session.pre_question_done = cureQ;
            if (!cureQ) {
              state.section = session;
              state.currentView = "question";
              state.viewData = pre_questions;

              state.position = "pre";
              return cureQ;
            }
          }

          if (pre_video.length > 0) {
            for (let i = 0; i < pre_video.length; i++) {
              if (!obj[`v_${pre_video[i].id}`]) {
                console.log("Do not have vid");
                state.section = session;
                state.currentView = "video";
                state.viewData = pre_video[i];
                return false;
              } else {
                console.log("have vid");
                isNotSeen = false;
              }
            }
          }

          if (post_questions.length > 0) {
            const cureQ = post_questions.every((q) => {
              const isHave = !!obj[`q_${q.id}`];
              if (isHave) {
                isNotSeen = false;
              }
              return isHave;
            });

            session.post_question_done = cureQ;
            if (!cureQ) {
              state.currentView = "question";
              state.viewData = post_questions;
              state.section = session;
              state.position = "post";
              return cureQ;
            }
          }

          session.allDone = true;
          return true;
        });

        state.allDone = !!currentAllDone;
        if (state.allDone) {
          state.currentView = "rating";
        }
        console.log({ isNotSeen });
        if (isNotSeen) {
          state.currentView = "overview";
          state.viewData = null;
        }
        state.topic = topic;
      } catch (error) {
        console.log(error);
      }
    },
  },
  extraReducers: {
    [HYDRATE]: (state, action) => {
      state = action.payload.topic;
      return state;
    },
  },
});

// Actions
export const topicActions = TopicSlice.actions;

export default TopicSlice.reducer;
