import { ForkEffect } from "redux-saga/effects";
import { call, takeLatest, put } from "redux-saga/effects";
import { callAPI } from "../sagaUtils";
import { userActions } from "./user.reducer";

const types = {
  LOGIN: "LOGIN",
  GET_USER: "GET_USER",
  LOGIN_SOCIAL: "LOGIN_SOCIAL",
};

export const userSagaActions = {
  login: (payload: {
    username: string;
    password: string;
  }): { type: string; payload: any } => ({
    type: types.LOGIN,
    payload,
  }),
  loginSocial: (payload: any): { type: string; payload: any } => ({
    type: types.LOGIN_SOCIAL,
    payload,
  }),
  getUser: (): { type: string } => ({
    type: types.GET_USER,
  }),
};

function* loginSaga({
  payload,
}: {
  payload: {
    username: string;
    password: string;
  };
}): any {
  yield put(userActions.setLoading(true));
  try {
    const res = yield call(callAPI, {
      method: "post",
      path: "/login",
      data: payload,
    });

    yield put(userActions.setUser(res?.data?.data ?? {}));
  } catch (error) {}
  yield put(userActions.setLoading(false));
}

function* loginSocialSaga({ payload }: { payload: any }): any {
  yield put(userActions.setLoading(true));
  try {
    const res = yield call(callAPI, {
      method: "post",
      path: "/login-social",
      data: payload,
    });

    yield put(userActions.setUser(res?.data?.data ?? {}));
  } catch (error) {}
  yield put(userActions.setLoading(false));
}

function* getUserSaga(): any {
  console.log("Get user saga");
  yield put(userActions.setLoading(true));
  try {
    console.log("Get user");
    const res = yield call(callAPI, {
      method: "get",
      path: "/user",
    });
    console.log("User response", res);

    yield put(userActions.setUser(res?.data?.data ?? {}));
  } catch (error) {
    console.log("Get user error", {
      error,
    });
  }
  yield put(userActions.setLoading(false));
}

export default function* userSaga(): Generator<ForkEffect<void>> {
  yield takeLatest(types.LOGIN as any, loginSaga) as any;
  yield takeLatest(types.LOGIN_SOCIAL as any, loginSocialSaga) as any;
  yield takeLatest(types.GET_USER as any, getUserSaga) as any;
}
