import { createSlice } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";
import { UserStore } from "./types";

const initialState: UserStore = {
  loading: false,
  loginModalOpen: false,
  voucherModelOpen: false,
};

export const UserSlice = createSlice({
  name: "user-store",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setLoginModalOpen: (state, action) => {
      state.loginModalOpen = action.payload;
    },
    setVoucherModalOpen: (state, action) => {
      state.voucherModelOpen = action.payload;
    },
  },
  extraReducers: {
    [HYDRATE]: (state, action) => {
      state = action.payload.user;
      return state;
    },
  },
});

// Actions
export const userActions = UserSlice.actions;

export default UserSlice.reducer;
