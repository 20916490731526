import { ForkEffect } from "redux-saga/effects";
import axios from "axios";
import { call, takeLatest, put, select } from "redux-saga/effects";
import { IStore } from "stores/types";
import { topicActions } from "./topic.reducer";

const types = {
  GET_TOPIC: "GET_TOPIC",
  GET_LEARNING_PROCESS: "GET_LEARNING_PROCESS",
  GET_LEARNING_PROCESS_SET_NEXT: "GET_LEARNING_PROCESS_SET_NEXT",
  POST_LEARNING_PROGRESS: "POST_LEARNING_PROGRESS",
};

export const topicSagaActions = {
  getTopic: (payload: { slug: string }): { type: string; payload: any } => ({
    type: types.GET_TOPIC,
    payload,
  }),
  getLearningProgess: (payload: {
    id: string;
  }): { type: string; payload: any } => ({
    type: types.GET_LEARNING_PROCESS,
    payload,
  }),
  getLearningProgessSetNext: (payload: {
    id: string;
  }): { type: string; payload: any } => ({
    type: types.GET_LEARNING_PROCESS_SET_NEXT,
    payload,
  }),
  sendLearningProgress: (payload: any) => ({
    type: types.POST_LEARNING_PROGRESS,
    payload,
  }),
};

function* getTopicSaga({
  payload,
}: {
  payload: {
    slug: string;
  };
}): any {
  yield put(topicActions.setLoading(true));
  try {
    const res = yield call(axios, "/api/v1/lesson/" + payload.slug);

    yield put(topicActions.setCurrentTopic(res?.data?.data ?? {}));
  } catch (error) {}
  yield put(topicActions.setLoading(false));
}

function* getLearningProgressSaga({ payload }: any): any {
  yield put(topicActions.setLoading(true));
  try {
    const res = yield call(axios, "/api/v1/lesson-progress/" + payload.id);

    yield put(topicActions.setUserLearning(res?.data?.data ?? {}));
  } catch (error) {}
  yield put(topicActions.setLoading(false));
}

function* setGetLearningProgressAndSetNextItemSaga({ payload }: any): any {
  try {
    yield call(getLearningProgressSaga, { payload: { id: payload.id } });
    yield put(topicActions.setNextItem());
  } catch (error) {}
}

function* sendLearningProgressSaga({ payload }: any): any {
  yield put(topicActions.setLoading(true));
  console.log("Go on sen learning progres");
  try {
    let body: any = {};
    if (payload.type === "video") {
      const { topic, viewData } = yield select((store: IStore) => store.topic);
      console.log({
        topic,
        viewData,
      });
      body = {
        lesson_id: topic?.id,
        video_id: viewData.id,
        type: "video",
      };
    }

    yield call(axios.post, "/api/v1/lesson-progress/" + payload.id, body);

    yield call(setGetLearningProgressAndSetNextItemSaga, {
      payload: { id: payload.id },
    });

    // yield put(topicActions.setUserLearning(res?.data?.data ?? {}));
  } catch (error) {}
  yield put(topicActions.setLoading(false));
}

export default function* topicSaga(): Generator<ForkEffect<void>> {
  yield takeLatest(types.GET_TOPIC as any, getTopicSaga) as any;
  yield takeLatest(
    types.GET_LEARNING_PROCESS as any,
    getLearningProgressSaga
  ) as any;

  yield takeLatest(
    types.POST_LEARNING_PROGRESS as any,
    sendLearningProgressSaga
  ) as any;

  yield takeLatest(
    types.GET_LEARNING_PROCESS_SET_NEXT,
    setGetLearningProgressAndSetNextItemSaga
  );
}
