import { AxiosResponse } from "axios";
import { call, SelectEffectDescriptor, SimpleEffect } from "redux-saga/effects";
import { axiosInstance } from "../services/apiClient";

export function* callAPI({
  method = "get",
  path,
  options,
  data = {},
}: {
  method: "get" | "post" | "put" | "delete";
  path: string;
  options?: { [key: string]: any };
  data?: any;
}): Generator<AxiosResponse> | SimpleEffect<"SELECT", SelectEffectDescriptor> {
  const opt = options ?? {};

  opt["headers"] = {
    ...opt["headers"],
    withCredentials: true,
  };

  // handle post, put with data
  if (["post", "put"].includes(method.toLowerCase())) {
    return yield call(axiosInstance[method], path, data, opt) as any;
  }

  // handle get/delete
  return yield call(axiosInstance[method], path, opt) as any;
}
