import { createStore, applyMiddleware, compose } from "redux";
import window from "global/window";
import getConfig from "next/config";
import createSaga from "redux-saga";
import logger from "redux-logger";
import { persistReducer, createMigrate } from "redux-persist";
import storage from "redux-persist/lib/storage";
import reducer from "./reducer";
import saga from "./saga";
import { encryptTransform } from "redux-persist-transform-encrypt";
import { createWrapper } from "next-redux-wrapper";

const { publicRuntimeConfig } = getConfig();

const encryptor = encryptTransform({
  secretKey:
    publicRuntimeConfig?.ENCRYPT_REDUX_STORE_KEY ?? "my-super-secret-key",
});

const migrations = {
  // 'v0.0.10': state => ({
  //   user: state.user,
  // }),
};

const persistConfig = {
  key: "root",
  storage,
  keyPrefix: "v0.0.10:",
  whitelist: ["cart", "user", "checkout", "globalConfig"],
  transforms:
    publicRuntimeConfig?.ENCRYPT_REDUX_STORE === "true" ? [encryptor] : [],
  migrate: createMigrate(migrations, { debug: false }),
};

const sagaMiddleware = createSaga();
const persistedReducer = persistReducer(persistConfig, reducer);

export const initializeStore = (initialState = {}) => {
  let composeEnhancers = compose;
  if (
    process.env.NODE_ENV !== "production" &&
    typeof window === "object" &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ) {
    composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({});
  }

  const middlewares = [];
  if (process.browser) {
    middlewares.push(sagaMiddleware);
    if (
      !!publicRuntimeConfig.ENABLE_LOG &&
      publicRuntimeConfig.ENABLE_LOG === "true"
    ) {
      middlewares.push(logger);
    }
  }
  const enhancer = composeEnhancers(applyMiddleware(...middlewares));

  let store = createStore(persistedReducer, initialState, enhancer);
  if (process.browser) {
    sagaMiddleware.run(saga);
  }

  return store;
};

let reduxStore: any;

const getOrInitializeStore = (initialState: any = {}) => {
  // Always make a new store if server, otherwise state is shared between requests
  if (typeof window === "undefined") {
    const store = initializeStore(initialState);
    return store;
  }

  // Create store if unavailable on the client and set it on the window object
  if (!reduxStore) {
    reduxStore = initializeStore(initialState);
  }

  return reduxStore;
};

export const wrapper = createWrapper(getOrInitializeStore);
