import window from "global/window";

export const pageview = (url: string): void => {
  (window as any).gtag("config", process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS, {
    page_path: url,
  });
};

// log specific events happening.
export const sendGaEvent = ({ action, params }: any) => {
  (window as any).gtag("event", action, params);
};
